import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import axios from 'axios';

import SelectCategories from './components/SelectCategories';
import FormCategoryParity from './components/FormCategoryParity';
import Helpers from './utils/Helpers'
import { setCategories, setOptions, setSelectedItems, setDisabledButton, setPropertyToCategory } from './reduce/SelectActions';

class ContainerCategories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryEmpty: false,
            loading: true
        };
        this.addCategory = this.addCategory.bind(this);
        this.getOptions = this.getOptions.bind(this);
    }

    componentDidMount() {
        console.log(this.props);

        this.init()
    }

    init() {

        this.reset()

        this.getOptions(this.props.partnerId)
            .then(res => {
                // edit flow
                if(this.props.originalCategoriesList?.length){
                    this.populateCategories(this.sanitizePartnersData(this.props.originalCategoriesList), res) 
                }
            })
    }

    reset() {
        this.props.setCategories([])
        this.props.setOptions([])
    }

    getOptions(partnerId){
        return new Promise((resolve, reject) => {
            try {
                const BASE_URL = `${window.REACT_APP_URL}`;
        
                axios
                    .get(`${BASE_URL}/partner/${partnerId}/category`)
                    .then((response) => {
                        const results = response.data.results
                        const partnersData = Helpers.sortItems(results, "categoryName");
                        const options = this.sanitizePartnersData(partnersData)
                        
                        !options.length && this.props.setDisabledButton(true)

                        this.props.setOptions(results ? options: [])

                        options.length && !this.props.originalCategoriesList?.length && this.addCategory()

                        this.setState({ categoryEmpty: options.length? false : true, loading: false })

                        resolve(options || [])
                    })
                    .catch((error) => {
                        reject(error)    
                        if (error.response && JSON.stringify(error.response.status) === '401') {
                            this.setState({ categoryEmpty: true, loading: false })
                            this.props.setDisabledButton(true)
                            this.props.logout();
                        }
                    });
                
            } catch (error) {
                this.setState({ categoryEmpty: true, loading: false })
                this.props.setDisabledButton(true)
                reject(error)                
            }
        })

    }

    sanitizePartnersData(partnersData){
        return partnersData.map(item => ({
            ...item, 
            disabled: !item?.active && item.hasOwnProperty('active') && true,
            fullCategoryName: `${item.partnerCategoryId} - ${item.categoryName} ${!item?.active && item.hasOwnProperty('active') ? '(inativo)': ''}`
        }))
    }

    populateCategories(originalCategoriesList, optionsList = this.props.options){

        const options = originalCategoriesList
                            .map(({categoryName, categoryId, fullCategoryName}) => ({
                                fullCategoryName,
                                categoryName, 
                                categoryId, 
                                active: optionsList.filter(item => item.categoryId === categoryId )[0].active
                            }))
        const ids = options.map(({categoryId}) => categoryId)
        // list categories not selected 
        const otherCategories = Helpers.getOptionsByIds(optionsList, ids, true)
                                    .map(({categoryName, categoryId, fullCategoryName, active}) => ({
                                        fullCategoryName, 
                                        categoryName, 
                                        categoryId, 
                                        disabled: !active && true, 
                                        active
                                    }))                        

        const groupItems = Helpers.groupItemsByParityAndClubParity(originalCategoriesList)
        const categories = Helpers.populateOtherCategories(groupItems, options, otherCategories)
        this.props.setCategories(categories)

        !Helpers.isValidCategories(categories) && this.populateError(true)

        // disable itens options
        this.props.setOptions(Helpers.updateItemsByIds(optionsList, ids, 'disabled', true))
    }

    addCategory() {
        const newCategoriesList = [
            ...this.props.categories
        ]
        newCategoriesList.push(
                {
                    hasError: false, 
                    parityEsfera: 0,
                    parity: 0,
                    parityClubEsfera: 0,
                    clubParity: 0,
                    list: this.props.options, 
                    id: Helpers.generateToken()
                }
        )
        this.props.setCategories(newCategoriesList)
    }

    populateError(value) {
        this.props.categories.map((category, id) => {
            if(Helpers.isValidCategories([category])) return
            if(!Helpers.isValidCategories([category])) 
                return this.props.setPropertyToCategory({
                        name: 'hasError', 
                        value, 
                        id,
                    })
        })
    }

    componentDidUpdate(prevProps){
        if(prevProps.partnerId !== this.props.partnerId){
            return this.init()
        }
        if(prevProps.categoryHasError !== this.props.categoryHasError){
            this.populateError(this.props.categoryHasError);
        }
    }

    render() {
        return (
            <div className="categories__container">
                <div className="categories__header">
                    <p className='line'></p>

                    <div class="popover__wrapper categories__popover">
                        <div className="categories__details">
                            <button type="button" className='categories__button' disabled={this.props.disabledButton} onClick={this.addCategory}>+</button>
                        </div>
                        <div class="popover__content">
                            <p class="popover__message">Adicionar paridade.</p>
                        </div>
                    </div>

                </div>
                {
                    this.props.categories && this.props.categories.map((item, index) => (
                        <div key={item.id}> 
                            <SelectCategories categoryId={index} originalCategoriesList={this.props.originalCategoriesList || []}/>
                            <FormCategoryParity categoryId={index}/>
                        </div>
                    ))
                }
                {this.state.loading? (
                    <div className="alert alert-warning">Carregando...</div>
                ) : null}
                {this.state.categoryEmpty? (
                    <div className="alert alert-danger">Não há categorias cadastradas</div>
                ) : null}
                {
                    this.props.categories.length > 0 && <p className='line'></p>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({ 
    options: state.select.options,
    categories: state.select.categories,
    selectedItems: state.select.selectedItems,
    disabledButton: state.select.disabledButton
 });
const mapDispatchToProps = (dispatch) => bindActionCreators({ 
    setOptions,
    setCategories,
    setSelectedItems,
    setDisabledButton,
    setPropertyToCategory
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ContainerCategories);