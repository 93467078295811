import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import { 
    removeCategory, 
    setCategories, 
    setDisabledButton, 
    setOptions, 
    setPropertyToCategory, 
    setSelectedItems 
} from '../reduce/SelectActions';
import Helpers from '../utils/Helpers';

class SelectCategories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectToggleMenu: false,
            selectAll: false,
            searchTerm: '',
            listId: '',
            result: ''
        };
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSelectAllChange = this.handleSelectAllChange.bind(this);
        this.handlerCancelSelected = this.handlerCancelSelected.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this)
        this.showResult = this.showResult.bind(this)
        this.selectToggle = this.selectToggle.bind(this)
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this)
        this.handlerCheckSelectAll = this.handlerCheckSelectAll.bind(this)
    }

    componentDidMount() {
        console.log(this.props);
        const { categories, categoryId } = this.props

        if(categories.length) {
            this.setState({ listId: categories[categoryId].id })
            this.showResult()
        }
    }

    handleSearchChange(event) {
        this.setState({ 
            searchTerm: event.target.value,
            selectToggleMenu: event.target.value? true: false
        });
        this.handlerCheckSelectAll()
    }

    handlerCheckSelectAll(checked) {
        const filteredOptionsSize = this.filteredOptions.filter(({checked, disabled}) => disabled || checked).length
        const optionsSize = this.props.options.length
        if(this.state.searchTerm){
            (checked ? (filteredOptionsSize + 1): (filteredOptionsSize - 1)) === this.filteredOptions.length
                ? this.setState({selectAll: true})
                : this.setState({selectAll: false})
        } else {
            if ((checked ? (filteredOptionsSize + 1): (filteredOptionsSize - 1)) === optionsSize){
                this.props.setDisabledButton(checked)
                this.setState({selectAll: true})
            } else {
                this.props.setDisabledButton(false)
                this.setState({selectAll: false})
            }
        }
    }

    get filteredOptions() {
        const id = this.props.categoryId
        return this.props.categories[id].list.filter(({fullCategoryName}) =>
            fullCategoryName.toLowerCase().includes(this.state.searchTerm.toLowerCase())
        );
    }

    selectToggle() {
        this.setState({ 
            selectToggleMenu: !this.state.selectToggleMenu,
            searchTerm:''
        })
    }

    /**
     * cancel or clean itens select by list.
     * @param {boolean} isRemovedByCategoryId 
     */
    handlerCancelSelected(isRemovedByCategoryId) {
        const { 
            removeCategory, 
            categoryId, 
            categories,
            setOptions, 
            options
        } = this.props;

        this.setState({ 
            selectAll: this.state.selectAll && false,
            searchTerm: '',
            result: ''
        })

        const ids = Helpers.listIdsByChecked(categories[categoryId].list)
        const listIds = categories.map((i, index) => index).filter(i => i !== categoryId)

        listIds.length && this.updateCategories(listIds, ids, 'disabled', false)

        this.props.setDisabledButton(false)

        setOptions(Helpers.updateItemsByIds(options, ids, 'disabled', false))

        if(isRemovedByCategoryId) return removeCategory(categoryId)   

        const items = categories[categoryId].list.map(item => ({...item, checked: false}))

        this.props.setPropertyToCategory({
            name: "list", 
            value: items, 
            id: categoryId,
        })
        
    }

    handlerSubmit() {
        this.setState({ 
            selectToggleMenu: false,
            searchTerm: ''
        })

        this.showResult()
    }

    /**
     * 
     * @param {[string]} categoriesId index categories ex: [0, 1 ,2]
     * @param {[string]} ids id the categorie ex: ['464f5d8f-0c54-4656-ba50-5b973353a3df']
     * @param {string} name propName ex: checked, disabled 
     * @param {boolean} status 
     */
    updateCategories(categoriesId, ids, name, value){
        categoriesId.map(categoryId => {
            const newList = Helpers.updateItemsByIds(this.props.categories[categoryId].list, ids, name, value )
            this.props.setPropertyToCategory({
                name: "list", 
                value: newList, 
                id: categoryId,
            })
        })
    }

    updateOptionsToProperties(status, list) {
        this.props.setOptions(
            Helpers.updateItemsByIds(
                this.props.options, 
                list? list :this.props.selectedItems, 
                'disabled', 
                status
            )
        )
    }

    showResult(ids) {

        const { categoryId } = this.props
        const list = this.props.categories[categoryId].list
        const selectedItems = ids ? ids :Helpers.listIdsByChecked(list)

        const options = this.props.options

        if (selectedItems.length) {
            const labels = selectedItems.map((value) => {
                const option = options.find(({categoryId}) => categoryId === value);
                return option && <span key={value} className="details">{option.fullCategoryName}</span>;
            });

            const allSelected = <span className="details">Todas</span>;

            if (labels.length <= 3) {
                this.setState({ result: labels })
            }
            if (labels.length > 3 && labels.length !== options.length) {
                this.setState({ result: labels.slice(0, 3).concat(<span className="clean">+{labels.length - 3}</span>) })
            }
            if (labels.length === options.length) {
                this.setState({ result: allSelected })
            }
        } else {
            this.setState({result: ''})
        }
    }

    updateSelectedItems(checked, categoryId) {
        this.props.setSelectedItems(
            checked
                ? [...this.props.selectedItems, categoryId]
                : this.props.selectedItems.filter(item => item !== categoryId)
        )
    }

    handleCheckBoxChange(checked, itemIndex, categoryId) {
        const categories = this.props.categories
        const id = this.props.categoryId

        const updateItems = categories.map((category, categoryIndex) => ({
            ...category,
            list: category.list.map(item => 
                item.categoryId === this.filteredOptions[itemIndex].categoryId
                    ?   {
                            ...item,
                            checked: categoryIndex === id ? !item.checked : item.checked,
                            disabled: categoryIndex !== id ? !item.disabled : item.disabled,
                        } 
                    : item
            )}
        ))

        this.updateSelectedItems(checked, categoryId)
        this.props.setCategories(updateItems)
        this.updateOptionsToProperties(checked, [categoryId])
        this.handlerCheckSelectAll(checked)

        this.handleCheckHasError(updateItems[id], id)

        const idsList = Helpers.listIdsByChecked(updateItems[id].list)
        this.showResult(idsList)
    }

    handleCheckHasError(category, id) {
        this.props.setPropertyToCategory({
            name: 'hasError', 
            value: !Helpers.isValidCategories([category]), 
            id,
        })
    }

    handleSelectAllChange(checked) {
        // ids to be changed in all lists
        const ids = this.filteredOptions
            .filter(({ disabled, checked, active}) => !disabled && !checked && active)
            .map(({categoryId}) => categoryId)
        const categories = this.props.categories
        const id = this.props.categoryId

        this.setState({selectAll: checked})
        this.props.setDisabledButton(checked)

        if(ids) {
            const updateItems = categories.map((category, index) => ({
                ...category,
                list: category.list.map(item => 
                    id === index
                        ? {
                            ...item,
                            checked: ids.includes(item.categoryId) && 
                                (!item.disabled || item.checked) || 
                                (item.checked && checked) ? true: false
                        } : {
                            ...item,
                            disabled: ids.includes(item.categoryId) || 
                                (item.disabled && checked) ||
                                !item.active ? true: false
                        }
                )}
            ))
            const idsList = Helpers.listIdsByChecked(updateItems[id].list)
            idsList.map(categoryId => this.updateSelectedItems(checked, categoryId))
            
            this.props.setCategories(updateItems)

            this.updateOptionsToProperties(checked, idsList.length 
                ? idsList
                : this.filteredOptions.filter(({ checked}) => checked).map(({categoryId}) => categoryId) )
            this.showResult(idsList)
        }
    }

    render() {
        return (
            <div className="select__control">
                <label htmlFor="">Categoria</label>
                <div className="select__container">
                    <div className="select__search">
                        <input
                            type="text"
                            id="search"
                            placeholder="Selecione"
                            value={this.state.searchTerm}
                            onChange={this.handleSearchChange}
                        />
                    </div>

                    <div className="select__separator"></div>

                    <div className="select__indicators" onClick={() => this.selectToggle()}>
                        <div className="select__indicator" aria-hidden="true"><svg
                            height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
                            <path
                                d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z">
                            </path>
                        </svg>
                        </div>
                    </div>

                </div>

                {
                    this.state.selectToggleMenu && (
                        <div className="select__options">
                            <ul>
                                {this.filteredOptions.map(({categoryId, fullCategoryName, disabled, checked}, index) => (
                                    <li key={categoryId + this.state.listId}>
                                        <label htmlFor={categoryId}>
                                            <input
                                                type="checkbox"
                                                id={categoryId + this.state.listId}
                                                value={categoryId}
                                                disabled={disabled}
                                                checked={checked}
                                                onChange={event => this.handleCheckBoxChange(event.target.checked, index, categoryId)}
                                            />
                                            {fullCategoryName}
                                        </label>
                                    </li>
                                ))}
                                <li className="select__footer">
                                    <button type="button" className="select__footer--button-ok" onClick={() => this.handlerCancelSelected(true)}>cancelar</button>
                                    <button type="button" onClick={() => this.handlerCancelSelected(false)}>limpar</button>
                                </li>
                            </ul>
                        </div>
                    )
                }
                <div className="select__result">{this.state.result}</div>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({ 
    options: state.select.options,
    categories: state.select.categories,
    selectedItems: state.select.selectedItems
 });
const mapDispatchToProps = (dispatch) => bindActionCreators({ 
    setOptions,
    setCategories,
    removeCategory,
    setSelectedItems,
    setDisabledButton,
    setPropertyToCategory
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SelectCategories);