import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import iconInfo from '../../../../assets/img/icon-info.svg';
import { setPropertiesToCategory, setPropertyToCategory } from '../reduce/SelectActions';
import Helpers from '../utils/Helpers';

class FormCategoryParity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clubParity: 0,
            parityClubEsfera: 0,
            parity: 0,
            parityEsfera: 0,
            sumPointsEsfera: 0,
            sumPointsClub: 0,
        };
        this.handleNumberInputChange = this.handleNumberInputChange.bind(this);
    }

    componentDidMount() {
        if(this.props.categories.length <= 0) return;
        if(this.props.categories.length > 0) {
            this.setState({
                sumPointsEsfera: Helpers.sumParities(
                    this.props.categories[this.props.categoryId].parityEsfera, 
                    this.props.categories[this.props.categoryId].parity
                ),
                sumPointsClub: Helpers.sumParities(
                    this.props.categories[this.props.categoryId].parityClubEsfera, 
                    this.props.categories[this.props.categoryId].clubParity
                ),
                parityEsfera: this.props.categories[this.props.categoryId].parityEsfera,
                parity: this.props.categories[this.props.categoryId].parity,
                parityClubEsfera: this.props.categories[this.props.categoryId].parityClubEsfera,
                clubParity: this.props.categories[this.props.categoryId].clubParity,
            })
        }
    }

    handleNumberInputChange = (event) => {
        event.preventDefault();
        const valueMask = Helpers.maskParity(event.target.value)

        this.props.setPropertiesToCategory({
            id: this.props.categoryId,
            properties: [{
                    name: event.target.name, 
                    value: valueMask, 
                },{
                    name: 'hasError', 
                    value: !Helpers.isValidCategories([{
                        ...this.props.categories[this.props.categoryId], 
                        [event.target.name]: valueMask 
                    }]), 
                }
            ]
        })
        this.setState({
            [event.target.name]: valueMask,
            [event.target.name + '_error']: '',
            successMsg: ''
        });
        this.setState((state, _) => ({
            sumPointsEsfera: Helpers.sumParities(state.parityEsfera, state.parity),
            sumPointsClub: Helpers.sumParities(state.parityClubEsfera, state.clubParity),
        }))
    };

    render() {
        return (
            <div className="form__container">
                <div className="div-parity">
                    <div className='input-block input-block-inside-div'>
                        <label htmlFor='esferaParity'>Paridade Esfera - Clientes Geral</label>
                        <input
                            type='text'
                            id='parityEsfera'
                            name='parityEsfera'
                            onChange={this.handleNumberInputChange}
                            value={this.props.categories[this.props.categoryId].parityEsfera}
                            maxLength='5'
                        />
                    </div>
                    <div className='error-msg'></div>

                    <div className="div-plus">
                        <span className="plus">+</span>
                    </div>

                    <div className='input-block'>
                        <label htmlFor='parity'>Paridade Parceiro - Clientes Geral</label>
                        <input
                            type='text'
                            id='parity'
                            name='parity'
                            onChange={this.handleNumberInputChange}
                            value={this.props.categories[this.props.categoryId].parity}
                            maxLength='5'
                        />
                    </div>
                    <div className='error-msg'></div>
                </div>
                <span className="sum-points">= &nbsp; <strong>{this.state.sumPointsEsfera} pts</strong> &nbsp; a cada R$ 1 Real em compras</span>

                <div className="div-parity">
                    <div className='input-block input-block-inside-div'>
                        <label htmlFor='esferaParity'>Paridade Esfera - Clientes Clube</label>
                        <input
                            type='text'
                            id='parityClubEsfera'
                            name='parityClubEsfera'
                            onChange={this.handleNumberInputChange}
                            value={this.props.categories[this.props.categoryId].parityClubEsfera}
                            maxLength='5'
                        />
                    </div>
                    <div className='error-msg'></div>

                    <div className="div-plus">
                        <span className="plus">+</span>
                    </div>

                    <div className='input-block'>
                        <label htmlFor='clubParity'>Paridade Parceiro - Clientes Clube</label>
                        <input
                            type='text'
                            id='clubParity'
                            name='clubParity'
                            onChange={this.handleNumberInputChange}
                            value={this.props.categories[this.props.categoryId].clubParity}
                            maxLength='5'
                        />
                    </div>
                    <div className='error-msg'></div>
                </div>
                <span className="sum-points">= &nbsp; <strong>{this.state.sumPointsClub} pts</strong> &nbsp; a cada R$ 1 Real em compras</span>

                <div className='error-msg'>{
                    this.props.categories[this.props.categoryId].hasError
                        && 'Inclua informação da paridade'
                }</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ 
    categories: state.select.categories
 });
const mapDispatchToProps = (dispatch) => bindActionCreators({ 
    setPropertiesToCategory,
    setPropertyToCategory
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FormCategoryParity);