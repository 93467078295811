const setOptions = (options) => {
    return {
        type: "SET_OPTIONS",
        payload: options,
    };
}

const setCategories = (categories) => {
    return {
        type: "SET_CATEGORIES",
        payload: categories,
    };
}

const setSelectedItems = (selectedItems) => {
    return {
        type: "SET_SELECTED_ITEMS",
        payload: selectedItems
    }
}

const removeSelectedItem = (selectedItem) => {
    return {
        type: "REMOVE_SELECTED_ITEM",
        payload: selectedItem
    }
}

const removeCategory = (index) => {
    return {
        type: "REMOVE_CATEGORY",
        payload: index
    }
}

const setPropertyToCategory = (payload) => {
    return {
        type: "SET_PROPERTY_TO_CATEGORY",
        payload
    }
}

const setPropertiesToCategory = (payload) => {
    return {
        type: "SET_PROPERTIES_TO_CATEGORY",
        payload
    }
}

const setDisabledButton = (status) => {
    return {
        type: "SET_DISABLED_BUTTON",
        payload: status
    }
}

export {
    setOptions,
    setCategories,
    setSelectedItems,
    setPropertyToCategory,
    setPropertiesToCategory,
    removeSelectedItem,
    setDisabledButton,
    removeCategory
}