const initialState = {
    options: [],
    categories: [],
    selectedItems: [],
    disabledButton: false
}

const SelectReducers = (state = initialState, action) => {
    try {
        switch (action.type) {
            case "SET_OPTIONS":
                return {...state, options: action.payload}
            case "SET_DISABLED_BUTTON":
                return {...state, disabledButton: action.payload}
            case "SET_CATEGORIES":
                return {...state, categories: action.payload}
            case "SET_SELECTED_ITEMS":
                return {...state, selectedItems: action.payload}
            case "REMOVE_SELECTED_ITEM":
                return {...state, selectedItems: state.selectedItems.filter(item => item !== action.payload ) }
            case "REMOVE_CATEGORY":
                return {...state, categories: state.categories.filter((item, index) => index !== action.payload ) }
            case "SET_PROPERTIES_TO_CATEGORY":
                const {id: categoryId, properties} = action.payload
                return {
                    ...state,
                    categories: state.categories.map((category, index) => {
                        const newObj = {}
                        index === categoryId && properties.forEach(({name, value})=> { newObj[name] = value })
                        return index === categoryId 
                                ? {...category, ...newObj }
                                : category
                })}
            case "SET_PROPERTY_TO_CATEGORY":
                const {id, name, value} = action.payload
                return {
                    ...state,
                    categories: state.categories.map((category, index) => 
                        index === id 
                            ? {...category, [name]: value }
                            : category
                )}
            default:
                return state;
        }
    } catch (error) {
        return state        
    }
}

export default SelectReducers;