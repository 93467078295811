
/**
 * 
 * @param {array} options ex [{...}]
 * @param {array} ids ex [1,2,3]
 * @param {boolean} except exclude ids
 * @returns array ex [{...}]
 */
const getOptionsByIds = (options, ids, except) => {
    return options.filter(({categoryId}) => except 
                ? !ids.includes(categoryId) 
                : ids.includes(categoryId)
            )
}

/**
 * 
 * @param {array} options ex [{...}]
 * @param {array} ids  ex [1,2,3]
 * @param {string} name ex 'disabled' || 'checked'
 * @param {any} value true || false
 * @returns array ex [{...}]
 */
const updateItemsByIds = (options, ids, name, value) => {
    return options.map(item => ids.includes(item.categoryId) 
            ? {...item, [name]: value }
            : item
    )
}

const groupItemsByParityAndClubParity = (categories) => {
    const group = {};

    categories.forEach(item => {
        const { 
            categoryId,
            categoryName,
            parityEsfera,
            parity,
            parityClubEsfera,
            clubParity,
            partnerCategoryId
        } = item
        const key = `${parityEsfera}-${parity}-${parityClubEsfera}-${clubParity}`;
        if (!group[key]) {
            group[key] = { 
                list: [], 
                parityEsfera,
                parity,
                parityClubEsfera,
                clubParity,
            };
        }
        group[key].list.push({
            fullCategoryName: `${partnerCategoryId} - ${categoryName}`,
            checked: true,
            categoryName,
            categoryId,
        });
    });

    return Object.values(group);
}

const listIdsByChecked = (list) => {
    return  list
            .filter(({checked}) => checked)
            .map(({categoryId}) => categoryId)
}

/**
 * 
 * @param {array} categories list categories
 * @param {array} options items selected from list
 * @param {array} otherCategories other itens not selected
 * @returns 
 */
const populateOtherCategories = (categories, options, otherCategories) => {
    return categories.map(item => {
        const list = options.map(option =>
            !item.list.map(({ categoryId }) => categoryId).includes(option.categoryId)
                ? { ...option, disabled: true }
                : { ...option, checked: true }
        )
        return { 
            ...item, 
            list: sortItems([
                ...list, 
                ...otherCategories
            ], "categoryName") 
        }
    })
}

const sortItems = (data, name) => {
    return data.sort(function (a, b) {
        if (a[name] < b[name]) {
            return -1;
        }
        if (a[name] > b[name]) {
            return 1;
        }
        return 0;
    });
}

const sanitizeCategories = (categories) => {
    const payload = [];
  
    categories.forEach(category => {
      category.list.forEach(item => {
        item.checked &&
            payload.push({
                categoryId: item.categoryId,
                categoryName: item.categoryName,
                parityEsfera: category.parityEsfera,
                parity: category.parity,
                parityClubEsfera: category.parityClubEsfera,
                clubParity: category.clubParity,
            });
      });
    });
  
    return payload;
}

const generateToken = () => Math.floor(Math.random() * 90000) + 10000

const sumParities = (esferaParity, partnerParity) => parseFloat( 
    (parseFloat(esferaParity || '0') + parseFloat(partnerParity || '0')).toFixed(2) 
);

const maskParity = (value) => {
    const parts = value
        .replace(/[^\d\.]/g, '')
        .split('.');
    if (parts.length > 1) {
      parts[1] = parts[1].substr(0, 2);
    }
    let newValue = parts.join('.');
    if (parseFloat(newValue) >= 100) {
      newValue = 99.99;
    }
    return newValue;
}

const isValidCategories = (categories) => {
    let check = true
    categories.forEach(({parityEsfera, parity, parityClubEsfera, clubParity, list }) => { if(
        list.filter(({checked}) => checked === true).length &&
        Number(parityClubEsfera) === 0 && 
        Number(parityEsfera) === 0 &&
        Number(clubParity) === 0 &&
        Number(parity) === 0
    ) return check = false } )
    return check
}

export default {
    groupItemsByParityAndClubParity,
    populateOtherCategories,
    isValidCategories,
    sanitizeCategories,
    updateItemsByIds,
    listIdsByChecked,
    getOptionsByIds,
    generateToken,
    sumParities,
    maskParity,
    sortItems
}