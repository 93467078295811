import React from 'react';
import ReactDOM from 'react-dom';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';

import promise from 'redux-promise';

import * as serviceWorker from './serviceWorker';
import reducers from './main/Reducers';
import App from './main/App';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import './index.css';
import './fonts/esfera-icons/style.css';

const store = applyMiddleware(promise)(createStore)(reducers)

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>
    ,
  document.getElementById('root')
);

serviceWorker.unregister();
